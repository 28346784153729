import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import { Header } from '../components/headers/Header'
import "./login.css"

export function Login() {

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [isError, setIsError] = useState(false)
    const [buttonVariant, setButtonVariant] = useState("primary")
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const validateLogin = () => {
        setIsError(false)
        setButtonVariant("primary")
        if (username === process.env.REACT_APP_BAUTH_USERNAME && password === process.env.REACT_APP_BAUTH_PASSWORD) {
            setIsLoggedIn(true)
        }
        else {
            setIsError(true)
            setButtonVariant("danger")
        }
    }

    return (
        <>
            {isLoggedIn ? <Navigate to="/dashboard" replace /> :
                <Container fluid className='p-5 d-flex h-100 flex-column'>
                    <Header />
                    <div className="containerFormLogin">
                        <Form className='d-flex flex-column w-100'>
                            <Row className=" justify-content-center align-items-center">
                                <Col md={3}>
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control type="text" value={username} onChange={(e) => { setUsername(e.target.value) }} />
                                </Col>
                            </Row>
                            <Row className="justify-content-center align-items-center pt-4">
                                <Col md={3}>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                </Col>
                            </Row>
                            <Row className="justify-content-center text-center" >
                                <Col md={3}>
                                    <Button variant={buttonVariant} className="mt-5 w-75" onClick={() => validateLogin()}>Login</Button>
                                </Col>
                            </Row>
                            {isError ? <Row className="error-message text-center justify-content-center m-auto pt-1">Invalid credentials</Row> : <div />}
                        </Form>
                    </div>
                </Container>
            }
        </>
    )
}