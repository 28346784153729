import './App.css';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import {EmailManager} from './pages/EmailManager'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Login } from './pages/Login';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<EmailManager />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
